<template>
  <div class="ps-5 pe-5">
    <div class="row">
      <div class="col-12">
        <div class="register">
          <div class="h-auto">

            <div class="row h-100 align-items-center pt-3 pb-4">
              <div class="col-12 col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">
                <div class="card shadow sm m-0 p-0">
                  <div class="card-body m-0 p-0">
                    <form action="javascript:void(0)" @submit="update" class="row form-register label-top text-start" enctype="multipart/form-data" method="post">
                      <div class="form-group col-12 pb-2">
                        <div class="row m-0 p-0">
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-3 col-md-3 col-lg-3 p-1 m-0 mt-3">
<!--                            @focus="handleFocus($event)"-->
<!--                            @focusout="handleFocusOut(user.title, $event)"-->
                            <select required
                                v-model="user.title"
                                class="form-select top cursor-pointer"
                                tabindex="-1"
                                aria-label="title"
                                name="title"
                                id="title"
                            >
                              <option value="" disabled></option>
                              <option v-for="(item , index) in titleOptions" v-bind:key="index" :value="item.text" >
                                {{item.text}}
                              </option>
                            </select>
                            <label class="font-weight-bold top">Title <span class="required"> *</span></label>
                          </div>
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 p-1 m-0 mt-3">
                            <input required type="text" name="name" maxlength="50" v-model="user.name" id="name" placeholder=" " class="form-control top">
                            <label for="name" class="font-weight-bold top">Name <span class="required"> *</span></label>
                          </div>
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-5 col-md-5 col-lg-5 p-1 m-0 mt-3">
                            <input required type="text" name="surname" maxlength="50" v-model="user.surname" id="surname" placeholder=" " class="form-control top">
                            <label for="surname" class="font-weight-bold top">Surname <span class="required"> *</span></label>
                          </div>
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.title" class="required-info text-center">
                          {{ this.validationErrors.title[0] }}
                        </p>
                        <p v-if="this.validationErrors && this.validationErrors.name" class="required-info text-center">
                          {{ this.validationErrors.name[0] }}
                        </p>
                        <p v-if="this.validationErrors && this.validationErrors.surname" class="required-info text-center">
                          {{ this.validationErrors.surname[0] }}
                        </p>
                      </div>

                      <!-- Upload User Image -->
                      <div class="row m-0 p-0 text-center">
                        <!-- User Image Upload -->
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-12">
<!--                              <p class="pb-2">Profile Image | Left: <span style="color: red;">{{ calculateAllowedImages(user.previewUserImage, user.editUserImage, 1) }}</span></p>-->
                              <p class="pb-2">Profile Image | Uploaded: <span style="color: red;">{{ calculateImages(user.previewUserImage, user.editUserImage) }} / 1</span></p>
                              <div class="dropbox">
                                <input type="file" accept="image/*" @change="uploadUserImage" name="previewUserImage" id="userImage" class="input-file">
                                <p>Click on the box or drop the image into the box in order to upload it (Max file size: 2MB)</p>
                                <div class="row p-0 m-0 mb-2">
<!--                                  &lt;!&ndash;                          <div v-for="image in user.previewUserImage" class="col-4 p-1 mt-1 mb-1">&ndash;&gt;-->
<!--                                  &lt;!&ndash;                            <img v-if="image" :src="image" class="uploading-user-image" />&ndash;&gt;-->
<!--                                  &lt;!&ndash;                          </div>&ndash;&gt;-->
                                  <!-- user.previewUserImage && user.previewUserImage.length > 0 -->
                                  <img v-if="user.previewUserImage && user.previewUserImage.length > 0" :src="user.previewUserImage[0]" class="uploading-user-image img-fluid d-block" />
                                </div>
<!--                                <div v-for="image in user.previewUserImage" class="col-4 p-1 mt-1 mb-1">-->
<!--                                  TEST TEST-->
<!--                                  {{ image }}-->
<!--                                  <img v-if="image" :src="image" class="uploading-user-image" />-->
<!--                                </div>-->
                              </div>
                              <div v-if="user.editUserImage && user.editUserImage.length > 0" class="col-12"> <!--  && user.editUserImage.length > 0 -->
                                <ul class="list-unstyled text-start p-1">
                                  <li v-for="(editUserImage, index) in user.editUserImage">
                                    <div class="row g-0 m-0 p-1">
                                      <div class="col-1">
                                        <div class="position-relative">
                                          <button @click="deleteImage(editUserImage.id, editUserImage.document_type_id, editUserImage.file_name)" class="delete-btn">X</button>
                                        </div>
                                      </div>
                                      <div class="col-5">
                                        {{ editUserImage.file_name }}
                                      </div>
                                      <div class="col-6">
                                        <img :src="`${$s3bucket}${editUserImage.file_path}`" @error="setUserDefaultImg"
                                             class="d-block img-fluid user-image"
                                             alt="..."
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!--
                            It would also be better to display name of the image rather than some random number..
                            not sure how to achieve that though
                          -->
                          <div v-for="(error, key, index) in this.validationErrors">
                            <p v-if="key.includes('userImage')" class="required-info text-center">
                              Image number {{ Number(key.substr(key.indexOf('.') + 1)) + 1 }} - {{ error[0] }}
                            </p>
                            <p v-if="key.includes('user_images_limit')" class="required-info text-center">
                              {{ error[0] }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- End Upload User Image -->

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Information -->
                      <small class="p-3 text-center">If you are not changing mobile, no need to send verification code</small>

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Start Recaptcha Verification Container -->
                      <div id="recaptcha-container"></div>
                      <!-- End Recaptcha Verification Container -->

                      <div class="form-group col-12 pt-2 pb-2">
                        <div class="row m-0 p-0 min-height-60">
                          <span data-tooltip="tooltip" class="info-tooltip">
                            <div class="no-text-wrap overflow-hidden text-truncate">
                              <font-awesome-icon icon="info-circle" class="icon-fa" style="color: orange;" />
                              <span class="tooltiptext">Prefix example: 44 or +44 for the UK</span>
                            </div>
                          </span>
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-2 col-md-2 col-lg-2 p-1 m-0 mt-3">
                            <input type="number" name="mobile-prefix" minlength="1" maxlength="7" v-model="user.mobile_prefix" id="mobile-prefix" placeholder=" " class="form-control top" style="padding: 0.375rem 0.95rem;">
                            <label for="mobile-prefix" class="font-weight-bold top">Prefix <span class="required"> *</span></label>
                          </div>
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 p-1 m-0 mt-3">
                            <input type="number" name="mobile" minlength="4" maxlength="15" v-model="user.mobile" id="mobile" placeholder=" " class="form-control top">
                            <label for="mobile" class="font-weight-bold top">Mobile <span class="required"> *</span></label>
                          </div>
                          <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 m-0 mt-3">
                            <button type="button" @click="sendCode()" :disabled="processing" :class="[user.codeSent ? 'btn btn-outline-success me-2' : 'btn btn-outline-warning me-2']">
                              {{ processing ? "Please wait" : "Send Code" }}
                            </button>
                            <div v-if="processing" class="text-center d-inline-block">
                              <div class="spinner-border text-info" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 pt-2 m-0 mt-3 position-relative cursor-pointer">
                            <span data-tooltip="tooltip">
                              <div class="no-text-wrap overflow-hidden text-truncate">
                                <p v-if="user.codeSent"><font-awesome-icon icon="check-circle" class="icon-fa price-color" /></p>
                                <p v-else><font-awesome-icon icon="times-circle" class="icon-fa required" /></p>
                                <span v-if="user.codeSent" class="tooltiptext">Status: Code Sent</span>
                                <span v-else class="tooltiptext">Status: Code Not Sent</span>
                              </div>
                            </span>
                          </div>

                          <!--                  <div class="form-group col-4 p-1 m-0">-->
                          <!--                    <label for="landing" class="font-weight-bold">Show mobile number</label><br/>-->
                          <!--                    <label class="switch mt-2">-->
                          <!--                      <input type="checkbox" v-model="user.show_mobile">-->
                          <!--                      <span class="slider round"></span>-->
                          <!--                    </label>-->
                          <!--                  </div>-->
                        </div>
                        <p v-if="this.sendCodeError" class="required-info text-center">
                          {{ this.sendCodeError }}
                        </p>
                        <p v-if="this.validationErrors && this.validationErrors.mobile_prefix" class="required-info text-center">
                          {{ this.validationErrors.mobile_prefix[0] }}
                        </p>
                        <p v-if="this.validationErrors && this.validationErrors.mobile" class="required-info text-center">
                          {{ this.validationErrors.mobile[0] }}
                        </p>
                        <!--                <p v-if="this.validationErrors && this.validationErrors.show_mobile" class="required-info text-center">-->
                        <!--                  {{ this.validationErrors.show_mobile[0] }}-->
                        <!--                </p>-->
                      </div>

                      <div class="form-group col-12 pb-3">
                        <div class="row m-0 p-0 min-height-60">
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-1 m-0 mt-3">
                            <input type="text" name="verificationCode" v-model="user.verificationCode" id="code" placeholder=" " class="form-control top">
                            <label for="code" class="font-weight-bold top">Verification Code <span class="required"> *</span></label>
                          </div>
                          <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 m-0 mt-3">
                            <button type="button" @click="verifyCode()" :disabled="processing" :class="[user.codeVerified ? 'btn btn-outline-success me-2' : 'btn btn-outline-warning me-2']">
                              {{ processing ? "Please wait" : "Verify Code" }}
                            </button>
                            <div v-if="processing" class="text-center d-inline-block">
                              <div class="spinner-border text-info" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-6 col-xs-6 col-sm-3 col-md-3 col-lg-3 p-1 pt-2 m-0 mt-3 position-relative cursor-pointer">
                            <span data-tooltip="tooltip">
                              <div class="no-text-wrap overflow-hidden text-truncate">
                                <p v-if="user.codeVerified"><font-awesome-icon icon="check-circle" class="icon-fa price-color" /></p>
                                <p v-else><font-awesome-icon icon="times-circle" class="icon-fa required" /></p>
                                <span v-if="user.codeVerified" class="tooltiptext">Status: Verified</span>
                                <span v-else class="tooltiptext">Status: Not Verified</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <p v-if="this.verificationCodeError" class="required-info text-center">
                          {{ this.verificationCodeError }}
                        </p>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

<!--                      <div class="form-group col-12 pb-2">-->
<!--                        <div class="row m-0 p-0">-->
<!--                          <div class="form-group col-12 p-1 m-0">-->
<!--                            <label for="email" class="font-weight-bold">Email</label> <span class="required">*</span>-->
<!--                            <input type="text" name="email" maxlength="100" v-model="user.email" id="email" placeholder="Enter email" class="form-control">-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <p v-if="this.validationErrors && this.validationErrors.email" class="required-info text-center">-->
<!--                          {{ this.validationErrors.email[0] }}-->
<!--                        </p>-->
<!--                      </div>-->

                      <div class="form-group col-12 pt-2 pb-3">
                        <div class="row m-0 p-0">
                          <span data-tooltip="tooltip" class="info-tooltip">
                            <div class="no-text-wrap overflow-hidden text-truncate">
                              <font-awesome-icon icon="info-circle" class="icon-fa" style="color: orange;" />
                              <span class="tooltiptext">Password should be made of minimum 8 characters</span>
                            </div>
                          </span>
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-1 m-0 mt-3">
                            <input required type="password" name="password" v-model="user.password" id="password" placeholder=" " class="form-control top">
                            <label for="password" class="font-weight-bold top">Password <span class="required"> *</span></label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-12 pb-3">
                        <div class="row m-0 p-0">
                          <div class="form-group form-floating-label col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 p-1 m-0 mt-3">
                            <input required type="password" name="password_confirmation" v-model="user.password_confirmation" id="password_confirmation" placeholder=" " class="form-control top">
                            <label for="password_confirmation" class="font-weight-bold top">Confirm Password <span class="required"> *</span></label>
                          </div>
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.password" class="required-info text-center">
                          {{ this.validationErrors.password[0] }}
                        </p>
                        <p v-if="this.validationErrors && this.validationErrors.wrong_password" class="required-info text-center">
                          {{ this.validationErrors.wrong_password[0] }}
                        </p>
                      </div>

                      <div class="col-12 mb-2 text-center">
                        <button type="submit" :disabled="processing" class="btn-branding border-branding btn-block min-width-100">
                          {{ processing ? "Please wait" : "Update" }}
                        </button>
                        <p v-if="this.validationMessage" class="required-info text-center">
                          {{ this.validationMessage }} Please scroll up to see error messages.
                        </p>
                        <p v-if="this.showError && this.validationSuccess" class="success-info text-center">
                          {{ this.validationSuccess }}
                        </p>
                      </div>

                      <div class="col-12 text-center">
                        <label>Want to change your password? <router-link :to="{name:'forgotpassword'}">Click here!</router-link></label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import firebase from 'firebase/compat/app'
import axios from 'axios'

export default {
  name:"DashboardUserSettings",
  props: ['prefixVerified', 'mobileVerified'],
  data(){
    return {
      recaptchaVerifier: null,
      codeResult: null,
      sendCodeError: '',
      verificationCodeError: '',
      user:{
        id: '',
        title: '',
        name: '',
        surname: '',
        previewUserImage: [],
        userImage: [],
        editUserImage: [],
        mobile_prefix: '',
        mobile: '',
        verificationCode: '',
        codeSent: false,
        codeVerified: false,
        // email: '',
        password: '',
        password_confirmation: ''
      },
      titleOptions: [
        { value: '1', text: 'Mr' },
        { value: '2', text: 'Mrs' },
        { value: '3', text: 'Miss' },
        { value: '4', text: 'Ms' }
      ],
      processing:false,
      validationErrors: '',
      validationMessage: '',
      validationSuccess: '',
      showError: false
    }
  },
  async mounted() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': () => {
        console.log('reCaptcha Loaded');
      }
    });
    await this.recaptchaVerifier.render();
    // this.getUserData(this.$store.state.auth.user.id)
    this.assignUserValues()
  },
  methods:{
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    ...mapActions({
      signIn:'auth/login'
    }),
    assignUserValues() {
      this.user.id = this.$store.state.auth.user.id;
      this.user.title = this.$store.state.auth.user.contact.title;
      this.user.name = this.$store.state.auth.user.contact.name;
      this.user.surname = this.$store.state.auth.user.contact.surname;
      this.user.mobile_prefix = this.$store.state.auth.user.contact.mobile_prefix;
      this.user.mobile = this.$store.state.auth.user.contact.mobile;
      // For Firebase verification (set props)
      this.prefixVerified = this.user.mobile_prefix;
      this.mobileVerified = this.user.mobile;
      // this.user.email = this.$store.state.auth.user.contact.email;

      // Assign Service Images
      if (this.$store.state.auth.user.documents_user_images) { // && this.$store.state.auth.user.documents_user_images.length > 0
        this.user.editUserImage.push(this.$store.state.auth.user.documents_user_images);
        // Object.keys(this.$store.state.auth.user.documents_user_images).forEach((propKey) => {
          // console.log('Key : ' + propKey + ', Value : ' + this.$store.state.auth.user.documents_user_images[propKey]);
          // this.user.editUserImage.push(this.$store.state.auth.user.documents_user_images[propKey]);

          // let requireImage = "require(`@/assets/" + this.editData.data.documents_service_images[propKey].file_path + "`)";
          // console.log(requireImage);
          // I am encountering new problem here, I need to either store also base64 string of images into db
          // or blob... as fileReader() cannot handle just the URL in form of file_path.. I need somehow to get
          // images being previewed on PreviewAdDetails.vue... and also list the rest of the files as a list, so one can
          // remove them on click (remove from DB)
          // My solution might need revision, as it seems a bit pointless to do it that way as described above,
          // I will be using S3 buckets so I should make sure to make it working for S3 buckets, on AddEditAd, AdDetails,
          // List and PreviewAdDetails components
        // })
      }
    },
    calculateImages(images = null, editImages = null) {
      // Check how many images added compared to set limit
      let count = 0;
      let countEditImages = 0;

      if (images) {
        count = images.length;
      }
      // check if edit images exists
      if (editImages) {
        countEditImages = editImages.length;
      }

      return count + countEditImages;
    },
    calculateAllowedImages(images, editImages = null, limit) {
      // Check how many images added compared to set limit
      if (images) {
        let count = images.length;
        let countEditImages = 0;

        // check if edit images exists
        if (editImages) {
          countEditImages = editImages.length;
        }
        if (limit - count - countEditImages >= 0) {
          return limit - count - countEditImages;
        } else {
          return 'Exceeded allowed number of images';
        }
        // Return maximum allowed
      } else {
        return limit;
      }
    },
    sendCode() {
      let that = this;
      let number = null;
      that.processing = true;
      that.sendCodeError = '';
      if (this.user.mobile_prefix && this.user.mobile) {
        if (this.user.mobile_prefix.charAt(0) === '+') {
          number = this.user.mobile_prefix + this.user.mobile;
        } else if (!isNaN(this.user.mobile_prefix)) {
          number = '+' + this.user.mobile_prefix + this.user.mobile;
        }
        // Set props so we can make sure that during registration fields still contain same mobile and prefix numbers
        that.prefixVerified = this.user.mobile_prefix;
        that.mobileVerified = this.user.mobile;
      } else if (this.user.mobile) {
        this.user.mobile_prefix = 44;
        number = '+44' + that.user.mobile;
        // Set props so we can make sure that during registration fields still contain same mobile and prefix numbers
        that.prefixVerified = this.user.mobile_prefix;
        that.mobileVerified = this.user.mobile;
      } else {
        that.sendCodeError = 'Prefix and mobile are required fields';
        that.processing = false;
        return;
      }

      firebase.auth().signInWithPhoneNumber(number, this.recaptchaVerifier).then(function (confirmationResult) {
        that.codeResult = confirmationResult;
        that.sendCodeError = '';
        if (that.validationErrors.mobile_prefix) {
          that.validationErrors.mobile_prefix = '';
        }
        if (that.validationErrors.mobile) {
          that.validationErrors.mobile = '';
        }
        that.user.codeSent = true;
        that.processing = false;
      }).catch(function (error) {
        that.sendCodeError = error.message;
        // Error; SMS not sent
        if (error.code === "auth/too-many-requests") {
          that.sendCodeError = "Unable to verify Captcha at this time. Please try again after some time";
        }
        that.user.codeSent = false;
        that.processing = false;
      });
    },
    verifyCode() {
      let that = this;
      that.processing = true;
      if (this.codeResult) {
        this.codeResult.confirm(this.user.verificationCode).then(function (result) {
          that.verificationCodeError = '';
          that.user.codeVerified = true;
          that.processing = false;
        }).catch(function (error) {
          that.verificationCodeError = error.message;
          that.user.codeVerified = false;
          that.processing = false;
        });
      }
      that.processing = false;
    },
    async update(){
      this.processing = true

      let formData = new FormData();

      // Append all form data, also if statement changes true and false into 0 and 1
      for (let key in this.user) {
        if(this.user[key] === false) {
          this.user[key] = 0;
        } else if(this.user[key] === true) {
          this.user[key] = 1;
        }
        formData.append(key, this.user[key]);
      }
      // Append user image
      if (this.user.userImage.length !== 0) {
        this.user.userImage.forEach((userImage, index) => {
          formData.append("userImage[]", userImage)
        })
      }

      // Loop formData and check if mobile and prefix were not manipulated after verification code was accepted
      for (let pair of formData.entries()) {
        if (pair[0] === 'mobile') {
          if (pair[1] != this.mobileVerified) {
            this.sendCodeError = 'Your prefix or mobile number was changed, it does not match number that was used for verification, either verify number again or reverse changes';
            this.processing = false;
            return;
          }
        }
        if (pair[0] === 'mobile_prefix') {
          if (pair[1] != this.prefixVerified) {
            this.sendCodeError = 'Your prefix or mobile number was changed, it does not match number that was used for verification, either verify number again or reverse changes';
            this.processing = false;
            return;
          }
        }
      }

      await axios.get('/sanctum/csrf-cookie')
      await axios.post('api/user/' + this.user.id + '/update', formData).then(({data})=>{
        this.validationSuccess = data.success;
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
        this.validationMessage = null;
        this.sendCodeError = '';
        this.verificationCodeError = '';
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(()=>{
        this.processing = false

        // Refresh User Data
        this.$root.$emit('globalRefreshUser');
      })
    },
    uploadUserImage(e) {
      this.user.previewUserImage = [];
      this.user.userImage = [];

      let fileList = Array.prototype.slice.call(e.target.files);
      fileList.forEach(f => {

        if(!f.type.match("image.*")) {
          return;
        }

        this.user.userImage.push(f)

        let reader = new FileReader();
        let that = this;

        reader.onload = function (e) {
          that.user.previewUserImage.push(e.target.result);
        }
        reader.readAsDataURL(f);
      });
    },
    deleteImage(id, type, name) {

      if (confirm("Do you really want to delete " + name + " ?")) {
        axios.post('api/document/' + id + '/delete').then(({data}) => {
          // Type 1 = User Image
          if (type === 1) {
            this.user.editUserImage = this.user.editUserImage.filter(userImage => userImage.id !== id);
          }
        })
      }
    },
  }
}
</script>


<style scoped lang="scss">

.info-tooltip {
  position: absolute;
  width: 120px;
  padding: 0 0 0 2px;
  margin-top: -5px;
}
/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  left: 40%;
  top: 20px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

.register .form-register {
  padding: 30px 20px 30px 20px;
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}

.register .card {
  overflow: hidden;
  border: 0 !important;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(4px) !important;
  input {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  textarea {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}

.dashboard-header {
  letter-spacing: 2px;
}

/* Start Upload File */
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1em;
  text-align: center;
  padding: 10px 0;
}

.uploading-user-image {
  width: 50%;
  margin: auto;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.delete-btn {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  margin: auto;
  font-size: 9px;
  padding: 0;
  transition: 0.6s;
  border: 0;
  color: white;
  background-color: #dc3545;
}

.delete-btn:hover {
  color: black;
  border: 1px solid #dc3545 !important;
  background-color: white;
  transition: 0.6s;
}

/* End Upload File */

</style>